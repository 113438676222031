<template>
  <div class="chip-container d-flex justify-start align-center align-items-center">
    <template v-if="formatStyle === 'chip'">
      <v-chip-group class="chip-group">
        <v-tooltip bottom content-class="title__tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="mx-0 px-1" v-on="on" v-bind="attrs" :style="chipClass('company')" small>
              {{ parsedUniqueId.company }}
            </v-chip>
          </template>
          <span>{{ $t('records.fields.company') }}</span>
        </v-tooltip>
        <v-tooltip bottom content-class="title__tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="mx-0 px-1" v-on="on" v-bind="attrs" :style="chipClass('records')" small>
              {{ parsedUniqueId.records }}
            </v-chip>
          </template>
          <span>{{ $t('records.fields.recordNumber') }}</span>
        </v-tooltip>
        <v-tooltip bottom content-class="title__tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="mx-0 px-1" v-on="on" v-bind="attrs" :style="chipClass('year')" small>
              {{ parsedUniqueId.year }}
            </v-chip>
          </template>
          <span>{{ $t('records.fields.year') }}</span>
        </v-tooltip>
      </v-chip-group>
    </template>
    <template v-else-if="formatStyle === 'text'">
      <div :style="textStyle">
        {{ label }}: {{ uniqueId }}
      </div>
    </template>
    <template v-else-if="formatStyle === 'raw'">
      <div :style="styles" class="d-flex justify-start align-center align-items-center">
        <strong>{{ rawUniqueId }}</strong>
      </div>
    </template>
    <v-chip class="mx-0 px-1 copy-chip" @click="copyUniqueId" small ripple>
      <v-icon small color="neuter mx-1">mdi-content-copy</v-icon>
    </v-chip>
  </div>
</template>

<script>
  export default {
    props: {
      uniqueId: String,
      textColor: {
        type: String,
        default: '#1f1f1f'
      },
      backgroundColor: {
        type: String,
        default: 'rgba(245,245,245,0.5)'
      },
      formatStyle: {
        type: String,
        default: 'chip'
      },
      formated: {
        type: Boolean,
        default: true
      },
      styles: {
        type: String
      },
      label: {
        type: String,
        default: 'Code'
      }
    },
    data() {
      return {
        showCopyIcon: false
      };
    },
    computed: {
      parsedUniqueId() {
        return {
          company: this.uniqueId.substr(0, 3),
          records: this.uniqueId.substr(3, 6),
          year: this.uniqueId.substr(9, 2)
        };
      },
      rawUniqueId() {
        const {company, records, year} = this.parsedUniqueId;

        if (this.formated)
          return `${company} ${records} ${year}`;
        else
          return `${company}${records}${year}`;
      },
      textStyle() {
        return `color: ${this.textColor}; background: ${this.backgroundColor}; padding: 5px; border-radius: 5px;`;
      }
    },
    methods: {
      chipClass(part) {
        return {
          'border-radius': part === 'company' ? '.3em 0 0 .3em !important' : part === 'year' ? '0 .3em .3em 0 !important' : '0 !important',
          'background': this.backgroundColor,
          'color': this.textColor,
          'font-weight': 'bold'
        };
      },
      copyUniqueId() {
        const el = document.createElement('textarea');
        el.value = this.uniqueId.replace(/\s/g, '');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    }
  }
</script>

<style scoped>
  .title__tooltip {
    background-color: rgba(0, 0, 0) !important;
    color: white;
    font-size: 12px;
    padding: 3px 5px;
  }

  .chip-container {
    position: relative;
    display: flex;
  }

  .copy-chip {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .chip-container:hover .copy-chip {
    opacity: 1;
  }

</style>

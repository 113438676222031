<template>
  <span>
    <!-- Tooltip with icon to open dialog -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          :disabled="disabled"
          v-on="on"
          color="neuter"
          small
          class="mr-2"
          @click="getDetails(item)"
        >
          mdi-eye-outline
        </v-icon>
      </template>
      <span>
        {{ $t('general.buttons.show') }}
        <span class="white--text" style="font-size: 12px;">
          {{ $t('menu.records') }}
        </span>
      </span>
    </v-tooltip>

    <!-- Usar el nuevo componente del diálogo -->
    <MoreDetailsDialog
      :value="dialogDetails"
      :recordSelected="recordSelected"
      @input="dialogDetails = $event"
    />
  </span>
</template>

<script>
  import moment from "moment";
  import { mapActions, mapGetters } from "vuex";
  import MoreDetailsDialog from "./MoreDetailsDialog.vue";

  export default {
    components: {
      MoreDetailsDialog
    },
    props: ['item', 'disabled'],
    data() {
      return {
        dialogDetails: false,
        loadingEvidences: false,
      };
    },
    computed: {
      ...mapGetters({
        recordsCompanyById: 'records/recordsCompanyById',
      }),
      recordSelected: {
        get() {
          return this.$store.getters['records/record'];
        },
        set(val) {
          this.$store.commit('records/SET_RECORD', val);
        },
      },
      logRecordSelected: {
        get() {
          return this.$store.getters['records/recordLog'];
        },
        set(val) {
          this.$store.commit('records/SET_RECORD_LOG', val);
        },
      },
      headers: {
        get() {
          return this.$store.getters['records/headers'];
        },
        set(val) {
          this.$store.commit('records/SET_HEADERS', val);
        },
      },
      existStructures() {
        return this.recordSelected.recordStructureValuesOrganized.structures &&
          this.recordSelected.recordStructureValuesOrganized.structures.length > 0;
      },
      getStructures() {
        return this.recordSelected.recordStructureValuesOrganized.structures || [];
      },
      existGroups() {
        return this.recordSelected.recordStructureValuesOrganized.groups &&
          this.recordSelected.recordStructureValuesOrganized.groups.length > 0;
      },
      getGroups() {
        return this.recordSelected.recordStructureValuesOrganized.groups || [];
      },
    },
    methods: {
      ...mapActions({
        findRecordById: 'records/findRecordById',
        findLogRecordById: 'records/findLogByRecordId',
      }),
      async getDetails(item) {
        this.$emit('on:load', true);

        const promises = [];
        promises.push(this.findRecordById([item.id, this.$toast]));
        promises.push(this.findLogRecordById([item.id, this.$toast]));

        Promise.all(promises).finally(() => {
          this.dialogDetails = true;
          this.$emit('on:load', false);
        });
      },
      closeDetails() {
        this.dialogDetails = false;
        this.$store.commit('records/RESTORE_RECORD');
        this.$store.commit('records/RESTORE_RECORD_LOG');
      },
      getDetailsMainData(type) {
        switch (type) {
          case 'record_date_time':
          case 'sent_date_time':
            return this.getDateFormat(this.recordsCompanyById[type]);
          case 'sdi_origin_name':
            return this.recordsCompanyById['sdi_origin'];
          case 'sdi_destination_name':
            return this.recordsCompanyById['sdi_destination'];
          default:
            return this.recordsCompanyById[type];
        }
      },
      getEvidences() {
        this.loadingEvidences = true;
        setTimeout(() => {
          this.loadingEvidences = false;
          alert('Evidences downloaded');
        }, 2000);
      },
      getDateFormat(dateString) {
        if (dateString) {
          return moment(new Date(dateString)).format(this.$store.getters['ui/GET_DATE_FILTERS'].dateFormat);
        }
        return null;
      },
      translate(key) {
        return this.$store.getters['language/translate'](key);
      },
      getValue(structure, value) {
        if (!value || Array.isArray(value) && value.length === 0) {
          return '-';
        }

        const dateFormatted = (this.$store.getters['language/getKey3'](value) + '.000Z').slice(0, 19);
        switch (structure.structure_type) {
          case 'DATA':
            return moment(dateFormatted).format('DD / MM / YYYY');
          case 'TIME':
            return moment(dateFormatted).format('HH:mm');
          case 'DATATIME':
            return moment(dateFormatted).format('DD / MM / YYYY HH:mm');
          default:
            return this.$store.getters['language/getKey3'](value);
        }
      },
    },
  };
</script>

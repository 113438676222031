import { render, staticRenderFns } from "./RecordsTabsComponent.vue?vue&type=template&id=3442c71f&scoped=true"
import script from "./RecordsTabsComponent.vue?vue&type=script&lang=js"
export * from "./RecordsTabsComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3442c71f",
  null
  
)

export default component.exports
<template>
    <v-container fluid>
        <v-list-item three-line>
            <v-list-item-content>
                <p class="display-1 text--primary">
                    {{$t('menu.appointments')}}
                </p>
            </v-list-item-content>
        </v-list-item>
        <v-tabs
                center-active
                :slider-size="3"
                color="primary"
                item-key="name"
                v-model="tabIndex"
                :value="tabIndex"
                show-arrows
        >
            <v-tab v-for="(storageTypeTab, index) in storageDeviceTypesActiveByCompany"
                   :key="index"
                   @click="getDataByFilters($event, storageTypeTab)"
                   :disabled="isDisable"
            >
                <v-img :src="storageTypeTab.iconBase64"
                       class="mb-1 mr-2"
                       max-height="30"
                       min-height="30"
                       max-width="30"
                       min-width="30"
                       eager
                />
                {{storageTypeTab.storage_type_name}}
            </v-tab>
        </v-tabs>
        <record-table :stage="stage" v-if="storageDeviceTypesActiveByCompany && storageDeviceTypesActiveByCompany.length > 0" @isDisable="isDisableTabs($event)"/>
    </v-container>
</template>

<script>
    import RecordTable from "./RecordsTableComponent";
    import _ from "lodash";

    export default {
        name: 'records-tab',
        props: ['stage'],
        components: {
            RecordTable
        },
        data: () => ({
            // User Profile
            profile: null,
            isDisable: true,
        }),

        async mounted() {
          if (this.stage) {
            _.forEach(this.storageDeviceTypesActiveByCompany, async (item, key) => {
              if (item.storage_device_type_id === this.stage) {
                this.tabIndex = key

                await this.getDataByFilters(null)
              }
            })
          } else {
            this.tabIndex = 0;
          }
        },

        watch: {
            stage(newValue, oldValue) {
                console.log(newValue)
                console.log(oldValue)
            }
        },

        computed: {
            storageDeviceTypesActiveByCompany() {
              return _.orderBy(this.$store.getters['storage_type/storageDeviceTypesActiveByCompany'], ['disposition']);
            },
            tabIndex: {
                get() {
                    return this.$store.getters['records/tabIndex'];
                },
                set(data) {
                    this.$store.commit('records/SET_TAB_INDEX', {data});
                }
            },
            filterRecord: {
                get() {
                    return this.$store.getters['ui/GET_STAGE_ID']
                },
                set(val) {
                    this.$store.dispatch('ui/SET_STAGE_ID', {val})
                }
            },
            filters: {
                get() {
                    return this.$store.getters['ui/GET_FILTERS']
                },
                set(val) {
                    this.$store.dispatch('ui/SET_FILTERS', val)
                }
            },
        },
        methods: {
            async getDataByFilters(e, storageTypeTab) {
                if (e) {
                    this.filterRecord.stageId = storageTypeTab.storage_device_type_id
                } else {
                  this.filterRecord.stageId = this.stage
                }

                this.isDisable = true;

                this.$root.$emit('setFiltersRecords');
            },
            isDisableTabs( event ){
                this.isDisable = event;
            }
        },

      destroyed() {
        this.filterRecord.stageId = null;
      }
    }
</script>

<style scoped>

</style>

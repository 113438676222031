
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { ButtonConfig } from "@/types/types";

  @Component
  export default class ButtonGroupComponent extends Vue {
    @Prop({ default: () => [] }) buttons!: ButtonConfig[];

    menuStates: { [key: number]: boolean } = {};

    mounted() {
      this.buttons.forEach((button, index) => {
        if (button.type === 'menu') {
          this.$set(this.menuStates, index, false);
        }
      });
    }

    getButtonStyle(index: number): object {
      const totalButtons = this.buttons.length;
      if (index === 0) {
        return { 'border-radius': '1.5em 0 0 1.5em' };
      } else if (index === totalButtons - 1) {
        return { 'border-radius': '0 1.5em 1.5em 0' };
      }
      return { 'border-radius': '0' };
    }

    closeMenu(index: number) {
      this.$set(this.menuStates, index, false);
    }
  }
